.mainContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftCol {
  background-color: #004b8c;
}

.form {
  border: solid 1px #282c34;
  padding: 40px 40px 10px 20px;
  width: 400px;
}