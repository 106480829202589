@import "./scss/loginPage/login-page";
@import "./scss/header/header";

*::-webkit-scrollbar-track {
  -webkit-box-shadow: white;
  background-color: white;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 7px;
  background-color: white;
}

*::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #d1d8dd;
}